exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-collections-index-js": () => import("./../../../src/pages/collections/index.js" /* webpackChunkName: "component---src-pages-collections-index-js" */),
  "component---src-pages-collections-kitchens-js": () => import("./../../../src/pages/collections/kitchens.js" /* webpackChunkName: "component---src-pages-collections-kitchens-js" */),
  "component---src-pages-collections-planters-js": () => import("./../../../src/pages/collections/planters.js" /* webpackChunkName: "component---src-pages-collections-planters-js" */),
  "component---src-pages-collections-seating-js": () => import("./../../../src/pages/collections/seating.js" /* webpackChunkName: "component---src-pages-collections-seating-js" */),
  "component---src-pages-collections-storage-js": () => import("./../../../src/pages/collections/storage.js" /* webpackChunkName: "component---src-pages-collections-storage-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-approach-js": () => import("./../../../src/pages/our-approach.js" /* webpackChunkName: "component---src-pages-our-approach-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

