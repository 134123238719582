import React, { createContext, useRef } from "react";

const defaultState = {};

const AppContext = createContext(defaultState);

const AppProvider = ({ children }) => {
  const headerRef = useRef(null);

  const store = {
    headerRef,
  };

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};

export default AppContext;

export { AppProvider };
